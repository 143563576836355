import firebase from "../config/fb.config";

export const postImprovement = (title, description) => async (dispatch) => {
  var db = firebase.firestore();
  await db
    .collection("improvements")
    .add({
      title: title,
      description: description,
      upvotes: 1,
    })
    .then((docRef) => {
      docRef
        .update({
          id: docRef.id,
        })
        .then(() => {
          dispatch({
            type: "POST_IMPROVEMENT",
            payload: {
              title: title,
              description: description,
              upvotes: 1,
              id: docRef.id,
            },
          });
        });
    })
    .catch((error) => {
      console.log("Error creating document:", error);
    });
};
