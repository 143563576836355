import firebase from 'firebase/app';
import 'firebase/firebase-firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDn3zw-Gibokt2mbP0sYFOFfg3T89wqT_o",
    authDomain: "pollsy-66735.firebaseapp.com",
    databaseURL: "https://pollsy-66735.firebaseio.com",
    projectId: "pollsy-66735",
    storageBucket: "pollsy-66735.appspot.com",
    messagingSenderId: "955602047343",
    appId: "1:955602047343:web:613a0c682bfc267a8e989a"
};

firebase.initializeApp(firebaseConfig);

export default firebase;