import React, { Component } from 'react';
import './ProgressBar.css';

export class ProgressBar extends Component {

    static defaultProps = {
        title: '',
        percentage: 0
    }

    handleClick = (e) => {
        this.props.handleClick(this.props.title);
    }

    render() {
        const percentage = this.props.hasVoted ? this.props.percentage : 0
        return (
            <div className="ProgressBar">
                <div className="TitleContainer">
                    <p className="Title">{this.props.title}</p>
                    {this.props.hasVoted ? (
                        <p className="Percentage">{this.props.percentage.toFixed(0)}%</p>
                    ) : (
                            <p></p>
                        )}
                </div>
                <div className="BarBackground" onClick={this.handleClick}>
                    <div className="AnimatedBar" style={{ width: `${percentage}%` }}></div>
                </div>
            </div>
        )
    }
}

export default ProgressBar
