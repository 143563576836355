import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

export class Footer extends Component {
    render() {
        return (
            <div className="Footer">
                <a href="mailto:support@pollsy.co">Support</a>
                <Link to='/terms-of-service'>Terms of Service</Link>
                <Link to='/privacy-policy'>Privacy Policy</Link>
            </div >
        )
    }
}

export default Footer
