import React, { Component } from 'react';
import avatar from '../../assets/img/avatar.jpg';
import './PollCardHeader.css';
import { connect } from 'react-redux';

export class PollCardHeader extends Component {
    render() {
        if (!this.props.poll) return null
        const { displayName, bitmojiUrl } = this.props.poll
        return (
            <div className="PollCardHeader">
                <div className="left-section">
                    <img src={bitmojiUrl} alt="profile" className="profile-picture" />
                    <h2 className="username-label">{displayName}</h2>
                </div>
                <button className="action-button"></button>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        poll: state.poll
    }
}

export default connect(mapStateToProps)(PollCardHeader);
