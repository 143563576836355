import React from "react";
import "./App.css";
import Poll from "./components/poll/Poll";
import PrivacyPolicy from "./components/privacyPolicy/PrivacyPolicy";
import TermsOfService from "./components/termsOfService/TermsOfService";
import FeatureRequests from "./components/featureRequests/FeatureRequests";
import { Route, Switch } from "react-router-dom";
import Home from "./components/home/Home";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/terms-of-service" component={TermsOfService} />
        <Route exact path="/improvements" component={FeatureRequests} />
        <Route path="/" component={Poll} />
      </Switch>
    </div>
  );
}

export default App;
