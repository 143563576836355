import firebase from "../config/fb.config";

export const fetchImprovements = () => async (dispatch) => {
  var db = firebase.firestore();
  var ref = db.collection("improvements");

  await ref.get().then((snapshot) => {
    let improvements = [];
    snapshot.forEach((doc) => {
      improvements.push(doc.data());
    });
    console.log(improvements);
    dispatch({
      type: "FETCH_IMPROVEMENTS",
      payload: {
        improvements: improvements,
      },
    });
  });
};
