import firebase from '../config/fb.config';

export const vote = (pollId, title) => {

    var db = firebase.firestore();
    const increment = firebase.firestore.FieldValue.increment(1);
    var docRef = db.collection("polls").doc(pollId);

    docRef.update({
        [`answers.${title}`]: increment,
        numberOfVotes: increment
    })
        .then(function () {
            console.log("Document successfully updated!");
        })
        .catch(function (error) {
            // The document probably doesn't exist.
            console.error("Error updating document: ", error);
        });

    return {
        type: 'VOTE',
        payload: {
            pollId: pollId,
            title: title
        }
    }
}