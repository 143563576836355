import { combineReducers } from "redux";

export const pollReducer = (state = null, action) => {
  console.log("Calling reducer...");
  if (action.type === "FETCH_POLL") {
    console.log(`From reducer ${state}`);
    return action.payload.poll;
  }

  if (action.type === "VOTE") {
    return {
      ...state,
      answers: answers(state.answers, action),
      numberOfVotes: state.numberOfVotes + 1,
    };
  }

  return state;
};

export const voteReducer = (state = false, action) => {
  if (action.type === "VOTE") {
    console.log("Handling vote..");
    return true;
  }

  return state;
};

const answers = (state = {}, action) => {
  console.log(typeof state);
  console.log(state[action.payload.title]);
  switch (action.type) {
    case "VOTE":
      return {
        ...state,
        [action.payload.title]: state[action.payload.title] + 1,
      };
    default:
      return state;
  }
};

const darkMode = (state = false, action) => {
  switch (action.type) {
    case "TOGGLE_DARK_MODE":
      return !state;
    default:
      return state;
  }
};

const improvementsReducer = (state = [], action) => {
  switch (action.type) {
    case "FETCH_IMPROVEMENTS":
      return action.payload.improvements;
    case "UPVOTE":
      console.log("STATE IS", state);
      console.log("ID IS:", action.payload.improvementId);
      return state.map((improvement, i) => {
        return improvement.id === action.payload.improvementId
          ? { ...improvement, upvotes: improvement.upvotes + 1 }
          : improvement;
      });
    default:
      return state;
  }
};

export default combineReducers({
  poll: pollReducer,
  hasVoted: voteReducer,
  isDarkMode: darkMode,
  improvements: improvementsReducer,
});
