import firebase from "../config/fb.config";

export const upvote = (improvementId) => {
  var db = firebase.firestore();
  const increment = firebase.firestore.FieldValue.increment(1);
  var docRef = db.collection("improvements").doc(improvementId);

  docRef
    .update({
      upvotes: increment,
    })
    .then(function () {
      console.log("Document successfully updated!");
    })
    .catch(function (error) {
      // The document probably doesn't exist.
      console.error("Error updating document: ", error);
    });

  return {
    type: "UPVOTE",
    payload: {
      improvementId: improvementId,
    },
  };
};
