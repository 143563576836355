import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./FeatureRequests.css";
import FeatureRequestCell from "../featureRequestCell/FeatureRequestCell";
import { fetchImprovements } from "../../actions/fetchImprovements";
import { postImprovement } from "../../actions/postImprovement";
import { connect } from "react-redux";
import Modal from "react-modal";

export class FeatureRequests extends Component {
  openModal = () => {
    this.setState({ modalIsOpen: true });
  };
  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  hanldeChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { title, description } = this.state;
    this.props.postImprovement(title, description);
    this.setState({
      modalIsOpen: false,
    });
  };

  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      title: "",
      description: "",
    };
  }
  componentDidMount() {
    this.props.fetchImprovements();
  }
  render() {
    const improvements = this.props.improvements.map((improvement, index) => {
      return (
        <FeatureRequestCell
          upvoteCount={improvement.upvotes}
          title={improvement.title}
          description={improvement.description}
          key={index}
          improvementId={improvement.id}
        />
      );
    });

    return (
      <div className="FeatureRequests">
        <h1 className="title">Pollsy</h1>
        <div className="suggestions-header">
          <p className="heading">Suggested improvements</p>
          <p className="more-info">
            If you have any ideas to make Pollsy better please let us know here!
          </p>
        </div>
        <div className="table-header">
          <p className="table-title" onClick={this.openModal}>
            + New
          </p>
        </div>
        {improvements}
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          className="Modal"
        >
          <h1 className="suggestion-modal-title">Make a suggestion</h1>
          <div className="form-container">
            <input
              type="text"
              placeholder="Title"
              className="form-title"
              value={this.state.title}
              name="title"
              onChange={this.hanldeChange}
            />
            <textarea
              name="description"
              id=""
              cols="30"
              rows="10"
              name="description"
              placeholder="Description goes here"
              value={this.state.description}
              onChange={this.hanldeChange}
            />
            <button className="submit-button" onClick={this.handleSubmit}>
              Submit
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

const maptStateToProps = (state) => {
  return {
    improvements: state.improvements,
  };
};

export default connect(maptStateToProps, {
  fetchImprovements,
  postImprovement,
})(withRouter(FeatureRequests));
