import React, { Component } from "react";
import "./Home.css";
import Footer from "../footer/Footer";
import mockup from "../../assets/img/iphone.png";
import pixel from "react-facebook-pixel";
export class Home extends Component {
  componentDidMount() {
    pixel.init("272097737297250");
    pixel.pageView();
  }

  render() {
    return (
      <div className="Home">
        <h1 className="title">Pollsy</h1>
        <div className="hero">
          <h1 className="hero-title">Create and share anonymous polls</h1>
          <p className="hero-description">
            Pollsy allows you to create polls and share them with your friends
            on Snapchat or other social media platforms. Users can upgrade their
            accounts for premium customization features.
          </p>
          <a
            href="https://apps.apple.com/us/app/pollsy-anonymous-polls/id1467870765?ls=1"
            className="download-button"
          >
            Download
          </a>
        </div>
        <div className="mockup">
          <img src={mockup} alt="iPhone" />
        </div>
        <Footer />
      </div>
    );
  }
}

export default Home;
