import React, { Component } from "react";
import Header from "../header/Header";
import "./TermsOfService.css";

export class TermsOfService extends Component {
  render() {
    return (
      <div className="TermsOfService">
        <Header />
        <div className="container">
          <h2>
            <strong>Pollsy Terms of Service</strong>
          </h2>
          <br></br>
          <p>
            <strong>1. Terms</strong> By accessing our app, Pollsy, you are
            agreeing to be bound by these terms of service, all applicable laws
            and regulations, and agree that you are responsible for compliance
            with any applicable local laws. If you do not agree with any of
            these terms, you are prohibited from using or accessing Pollsy. The
            materials contained in Pollsy are protected by applicable copyright
            and trademark law.
          </p>{" "}
          <p>
            <strong>2. Use License Permission</strong> is granted to temporarily
            download one copy of Pollsy per device for personal, non-commercial
            transitory viewing only. This is the grant of a license, not a
            transfer of title, and under this license you may not: modify or
            copy the materials; use the materials for any commercial purpose, or
            for any public display (commercial or non-commercial); attempt to
            decompile or reverse engineer any software contained in Pollsy;
            remove any copyright or other proprietary notations from the
            materials; or transfer the materials to another person or "mirror"
            the materials on any other server. This license shall automatically
            terminate if you violate any of these restrictions and may be
            terminated by Pollsy at any time. Upon terminating your viewing of
            these materials or upon the termination of this license, you must
            destroy any downloaded materials in your possession whether in
            electronic or printed format.
          </p>{" "}
          <p>
            <strong>3. Disclaimer</strong> The materials within Pollsy are
            provided on an 'as is' basis. Pollsy makes no warranties, expressed
            or implied, and hereby disclaims and negates all other warranties
            including, without limitation, implied warranties or conditions of
            merchantability, fitness for a particular purpose, or
            non-infringement of intellectual property or other violation of
            rights. Further, Pollsy does not warrant or make any representations
            concerning the accuracy, likely results, or reliability of the use
            of the materials on its website or otherwise relating to such
            materials or on any sites linked to Pollsy.
          </p>{" "}
          <p>
            <strong>4. Limitations</strong> In no event shall Pollsy or its
            suppliers be liable for any damages (including, without limitation,
            damages for loss of data or profit, or due to business interruption)
            arising out of the use or inability to use Pollsy, even if Pollsy or
            a Pollsy authorized representative has been notified orally or in
            writing of the possibility of such damage. Because some
            jurisdictions do not allow limitations on implied warranties, or
            limitations of liability for consequential or incidental damages,
            these limitations may not apply to you.
          </p>{" "}
          <p>
            <strong>5. Accuracy</strong> of materials The materials appearing in
            Pollsy could include technical, typographical, or photographic
            errors. Pollsy does not warrant that any of the materials on Pollsy
            are accurate, complete or current. Pollsy may make changes to the
            materials contained in Pollsy at any time without notice. However
            Pollsy does not make any commitment to update the materials.
          </p>{" "}
          <p>
            <strong>6. Links</strong> Pollsy has not reviewed all of the sites
            linked to its app and is not responsible for the contents of any
            such linked site. The inclusion of any link does not imply
            endorsement by Pollsy of the site. Use of any such linked website is
            at the user's own risk.
          </p>{" "}
          <p>
            <strong>7. Modifications</strong> Pollsy may revise these terms of
            service for its app at any time without notice. By using Pollsy you
            are agreeing to be bound by the then current version of these terms
            of service.
          </p>{" "}
          <p>
            <strong>8. Governing Law</strong> These terms and conditions are
            governed by and construed in accordance with the laws of Alabama and
            you irrevocably submit to the exclusive jurisdiction of the courts
            in that State or location.
          </p>{" "}
        </div>
      </div>
    );
  }
}

export default TermsOfService;
