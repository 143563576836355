import React, { Component } from "react";
import { connect } from "react-redux";
import { vote } from "../../actions/vote";
import { withRouter } from "react-router-dom";
import ProgressBar from "../progressBar/ProgressBar";
import "./PollContainer.css";
import PollCardInfoView from "../pollCardInfoView/PollCardInfoView";

export class PollContainer extends Component {
  componentDidMount() {
    const { pollId } = this.props;
    this.setState({
      hasVoted: localStorage.getItem(pollId) === "1",
    });
  }

  handleVote = (answer) => {
    if (this.state.hasVoted) {
      return;
    }

    const { pollId } = this.props;
    this.props.vote(pollId, answer);
    this.setState({
      hasVoted: true,
    });
    localStorage.setItem(pollId, "1");
  };

  getBackground() {
    if (typeof this.props.poll.gradient.primaryColor === "undefined") {
      return {
        backgroundColor: "black",
      };
    }
    if (this.props.poll.backgroundImage) {
      return {
        backgroundImage: `url(${this.props.poll.backgroundImage})`,
      };
    } else {
      return {
        backgroundImage: `linear-gradient(to left top, #${this.props.poll.gradient.primaryColor}, #${this.props.poll.gradient.secondaryColor})`,
      };
    }
  }

  render() {
    if (!this.props.poll) {
      return null;
    }

    const answers = Object.entries(this.props.poll.answers)
      .sort((a, b) => {
        return a[0] > b[0];
      })
      .map(([answer, numberOfVotes], index) => {
        return (
          <ProgressBar
            title={answer}
            handleClick={this.handleVote}
            percentage={(numberOfVotes / this.props.poll.numberOfVotes) * 100}
            key={answer}
            hasVoted={this.state.hasVoted}
          />
        );
      });

    const { numberOfVotes, expirationDate, shouldShowVotes } = this.props.poll;
    return (
      <div
        className={`PollContainer ${
          this.props.poll.backgroundImage && "PollContainerTint"
        }`}
        style={this.getBackground()}
      >
        <h1 className="question">{this.props.poll.title}</h1>
        {answers}
        <PollCardInfoView
          numberOfVotes={numberOfVotes}
          expirationDate={expirationDate}
          shouldShowVotes={shouldShowVotes}
        />
      </div>
    );
  }
}

const maptStateToProps = (state) => {
  return {
    poll: state.poll,
    hasVoted: state.hasVoted,
  };
};

export default connect(maptStateToProps, { vote })(withRouter(PollContainer));
