import firebase from '../config/fb.config';

export const fetchPoll = (pollId) => async dispatch => {

    var db = firebase.firestore();
    var docRef = db.collection("polls").doc(pollId);

    await docRef.get().then((doc) => {
        if (doc.exists) {
            console.log("Document data:", doc.data());
            dispatch({
                type: 'FETCH_POLL',
                payload: {
                    poll: doc.data()
                }
            })
        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }
    }).catch((error) => {
        console.log("Error getting document:", error);
    });
}