import React, { Component } from "react";
import "./FeatureRequestCell.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { upvote } from "../../actions/upvote";
import { connect } from "react-redux";

export class FeatureRequestCell extends Component {
  handleUpvote = () => {
    if (localStorage.hasOwnProperty(this.props.improvementId)) {
      return;
    }
    this.props.upvote(this.props.improvementId);
    localStorage.setItem(this.props.improvementId, true);
  };

  getIcon() {
    if (localStorage.hasOwnProperty(this.props.improvementId)) {
      return <FontAwesomeIcon icon={faCheck} color="green" />;
    }
    return <FontAwesomeIcon icon={faSortUp} />;
  }

  render() {
    return (
      <div className="FeatureRequestCell">
        <div className="text-content">
          <p className="item-title">{this.props.title}</p>
          <p className="item-description">{this.props.description}</p>
        </div>
        <div className="upvotes-container" onClick={this.handleUpvote}>
          {this.getIcon()}
          <p className="upvotes">{this.props.upvoteCount}</p>
        </div>
      </div>
    );
  }
}

export default connect(null, { upvote })(FeatureRequestCell);
