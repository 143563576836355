import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./Header.css";
import logo from "../../assets/img/logo-small.png";

export class Header extends Component {
  goToAppStore() {
    var win = window.open(
      "https://apps.apple.com/us/app/pollsy-anonymous-polls/id1467870765?ls=1",
      "_blank"
    );
    win.focus();
  }

  goHome = () => {
    this.props.history.push("/");
  };

  render() {
    return (
      <div className="Header">
        <div className="header-left-side" onClick={this.goHome}>
          <img src={logo} alt="logo" className="header-logo-icon" />
          <div className="header-text-container">
            <p className="header-title"> Pollsy - Polls for Snapchat </p>
            <p className="header-subtitle"> Start creating polls now </p>
          </div>
        </div>
        <button className="download-button" onClick={this.goToAppStore}>
          GET
        </button>
      </div>
    );
  }
}

export default withRouter(Header);
