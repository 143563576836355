import React, { Component } from "react";
import moment from "moment";
import "./PollCardInfoView.css";

export class PollCardInfoView extends Component {
  getFormattedTimeString() {
    const { expirationDate } = this.props;
    var prefix = "Polls ends ";
    if (expirationDate < Date() / 1000) {
      prefix = "Poll ended";
    }
    return expirationDate > Date() / 1000 ? "Poll ended" : "Active poll";
    return prefix + moment.unix(expirationDate).fromNow();
  }

  voteLabel() {
    if (this.props.shouldShowVotes) {
      return (
        <p className="vote-count-label">{this.props.numberOfVotes} votes</p>
      );
    }

    if (typeof this.props.shouldShowVotes === "undefined") {
      return (
        <p className="vote-count-label">{this.props.numberOfVotes} votes</p>
      );
    }
    return;
  }

  render() {
    return (
      <div className="PollCardInfoView">
        <p className="time-label">{this.getFormattedTimeString()}</p>
        {this.voteLabel()}
      </div>
    );
  }
}

export default PollCardInfoView;
