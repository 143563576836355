import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchPoll } from "../../actions/fetchPoll";
import { withRouter } from "react-router-dom";
import "./Poll.css";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import PollContainer from "../pollContainer/PollContainer";
import PollCardHeader from "../pollCardHeader/PollCardHeader";

export class Poll extends Component {
  componentDidMount() {
    const pollId = this.props.location.pathname.substr(1);

    this.props.fetchPoll(pollId);
  }

  render() {
    const pollId = this.props.location.pathname.substr(1);
    return (
      <div className="Poll">
        <Header />
        <PollCardHeader />
        <PollContainer pollId={pollId} />
        <a
          href="https://apps.apple.com/us/app/pollsy-anonymous-polls/id1467870765?ls=1"
          className="create-button"
        >
          Create your own poll 🙌🏻
        </a>
        <Footer />
      </div>
    );
  }
}

export default connect(null, { fetchPoll })(withRouter(Poll));
